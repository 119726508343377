module.exports = {
    siteTitle: "Romain GONÇALVES",
    siteDescription: "Ingénieur en développement Web",
    siteUrl: "https://3rgo.me",
    siteImage: "preview.jpg",
    siteLanguage: "fr",
    contactEmail: "contact@rgoncalves.fr",
    content: {
        about: [
            "J'ai découvert l'informatique à l'âge de 12 ans à la bibliothèque municipale. À 14 ans, j'apprenais le HTML et le CSS afin de réaliser mes premières pages statiques, et à 17 ans j'y ajoutais le PHP pour dynamiser l'ensemble.",
            "Depuis, je ne cesse de vouloir toujours en apprendre plus sur le monde du développement. Majoritairement auto-didacte, je prends plaisir à explorer de nouveaux langages et de nouvelles manières de solutionner les problèmes.",
            "Mes études supérieures, composées d'une classe préparatoire scientifique et d'une école d'ingénieurs m'ont apporté la rigueur et les méthodologies de travail, nécessaires afin d'être totalement autonome.",
            "Au travers mes expériences professionnelles, j'ai pu participer à des projets variés, tant par leur ampleur que par leur complexité, m'enrichissant toujours plus du contact avec d'autres développeurs et avec les équipes métier de tous secteurs d'activité.",
            "Tout ceci en gardant en tête ma passion première : améliorer la manière dont les autres travaillent."
        ],
        technologies: [
            [{ name: "PHP", logo: "php.svg", url: "http://php.net", level: 5 }],
            [
                { name: "Laravel", logo: "laravel.svg", url: "https://laravel.com", level: 4 },
                { name: "Symfony", logo: "symfony.svg", url: "https://symfony.com", level: 4 },
                { name: "Slim", logo: "slim.svg", url: "https://www.slimframework.com", level: 4 },
                { name: "Zend", logo: "zend.svg", url: "https://framework.zend.com", level: 2 },
                { name: "Lumen", logo: "lumen.svg", url: "https://lumen.laravel.com", level: 5 }
            ],
            [{ name: "Javascript", logo: "javascript.svg", url: "https://www.javascript.com", level: 5 }],
            [
                { name: "jQuery", logo: "jquery.svg", url: "http://jquery.com", level: 5 },
                { name: "React", logo: "react.svg", url: "https://reactjs.org", level: 3 },
                { name: "AngularJS", logo: "angularjs.svg", url: "https://angularjs.org", level: 2 },
                { name: "Node.js", logo: "nodejs.svg", url: "https://nodejs.org", level: 1 },
                { name: "MeteorJS", logo: "meteorjs.svg", url: "https://www.meteor.com", level: 2 }
            ],
            [
                { name: "MySQL", logo: "mysql.svg", url: "https://www.mysql.com", level: 4 },
                { name: "PostgreSQL", logo: "pgsql.svg", url: "https://www.postgresql.org", level: 4 },
                { name: "SQLite", logo: "sqlite.svg", url: "https://www.sqlite.org", level: 5 },
                { name: "MongoDB", logo: "mongodb.svg", url: "https://www.mongodb.com", level: 2 },
                { name: "MSSQL", logo: "mssql.svg", url: "https://www.microsoft.com/sql-server/", level: 3 }
            ],
            [
                { name: "HTML 5", logo: "html5.svg", url: "https://www.w3.org/html/", level: 5 },
                { name: "CSS 3", logo: "css3.svg", url: "https://www.w3.org/Style/CSS/", level: 4 },
                { name: "SASS", logo: "sass.svg", url: "https://sass-lang.com", level: 4 },
                { name: "Bootstrap", logo: "bootstrap.svg", url: "https://getbootstrap.com", level: 5 }
            ],
            [
                { name: "Python", logo: "python.svg", url: "https://www.python.org", level: 2 },
                { name: "Ruby", logo: "ruby.svg", url: "https://www.ruby-lang.org", level: 2 },
                { name: "Bash", logo: "bash.svg", url: "https://www.gnu.org/software/bash", level: 4 }
            ],
            [
                { name: "Git", logo: "git.svg", url: "https://git-scm.com", level: 4 },
                { name: "Mercurial", logo: "mercurial.svg", url: "https://www.mercurial-scm.org", level: 3 },
                { name: "Jenkins", logo: "jenkins.svg", url: "https://jenkins.io", level: 3 },
                { name: "Ansible", logo: "ansible.svg", url: "https://www.ansible.com", level: 2 },
                { name: "Webpack", logo: "webpack.svg", url: "https://webpack.js.org", level: 3 }
            ],
            [
                { name: "PHP Unit", logo: "phpunit.svg", url: "https://phpunit.de", level: 4 },
                { name: "Jasmine", logo: "jasmine.svg", url: "https://jasmine.github.io", level: 2 },
                { name: "Guzzle", logo: "guzzle.png", url: "http://guzzlephp.org", level: 2 }
            ],
            [
                { name: "Apache", logo: "apache.svg", url: "https://httpd.apache.org", level: 4 },
                { name: "Nginx", logo: "nginx.svg", url: "https://nginx.org", level: 3 }
            ],
            [
                { name: "Windows", logo: "windows.svg", url: "https://www.microsoft.com/windows/", level: 5 },
                { name: "macOS", logo: "macos.svg", url: "https://www.apple.com/macos", level: 4 },
                { name: "GNU/Linux", logo: "linux.svg", url: "https://www.gnu.org/", level: 4 }
            ],
            [
                { name: "JIRA", logo: "jira.svg", url: "https://www.atlassian.com/software/jira", level: 4 },
                { name: "Redmine", logo: "redmine.svg", url: "https://www.redmine.org", level: 4 },
                { name: "Microsoft Office", logo: "office.svg", url: "https://www.office.com/", level: 4 }
            ],
        ],
        experiences: [
            {
                start: "Janvier 2019",
                end: "Maintenant",
                name: "3RGOTECH",
                logo: "3rgo.tech.png",
                url: "https://3rgo.tech",
                job: "Développeur indépendant",
                history: [
                    "Conception et développement d'applications web sur mesure",
                    "Audit d'application et Expertise Technique",
                    "Formateur en développement (Algorithmie, PHP, Javascript, Conception Base de Données)"
                ]
            },
            {
                start: "Avril 2016",
                end: "Septembre 2018",
                name: "DECASOFT",
                logo: "decasoft.png",
                url: "http://www.decasoft.fr",
                job: "Chef de projets / Responsable TMA",
                history: [
                    {
                        client: "CCI Paris Île-de-France",
                        url: "https://www.cci-paris-idf.fr",
                        logo: "ccip.png",
                        actions: [
                            "Pilotage d'une équipe de 5 à 8 personnes pour la maintenance d'un parc d'une trentaine de sites (PHP, Drupal 7/8 et Wordpress)",
                            "Réalisation d'indicateurs mensuels et semestriels, et animation des comités de pilotage",
                            "Réalisation des devis et suivi de la facturation",
                            "Suivi qualité, veille sécurité, conseil sur l'orientation des développements",
                            "Maintenance et développement sur les sujets DevOps (Jenkins, Ansible)",
                            "Réalisation de spécifications fonctionnelles",
                            "Développement Drupal 8"
                        ]
                    },
                    {
                        client: "Parkéon France",
                        logo: "parkeon.png",
                        url: "https://www.parkeon.fr",
                        actions: [
                            "Réalisation des devis et suivi de la facturation",
                            "Développement d'applications \"machine à machine\" (PHP5, TDD et intégration continue)"
                        ]
                    },
                    {
                        client: "Altempo",
                        logo: "altempo.png",
                        url: "http://www.altempo.com",
                        actions: [
                            "Réalisation des devis et suivi de la facturation",
                            "Suivi des développements (équipe de 1 à 3 personnes)",
                            "Réalisation de user stories et de requirements techniques pour l'implémentation d'une pseudo-agilité"
                        ]
                    },
                    "Entretiens techniques aux candidats potentiels",
                    "Avant-vente avec les ingénieurs d'affaires",
                    "Configuration et maintenance des serveurs",
                    "Mise en place de process DevOps (GitLab, Jenkins, Ansible)",
                    "Support technique aux développeurs de l'agence",
                    "Gestion du matériel informatique"
                ]
            },
            {
                start: "Août 2011",
                end: "Avril 2016",
                name: "ALTEN Sud-Ouest",
                url: "http://www.alten.fr",
                logo: "alten.svg",
                job: "Ingénieur d'applications",
                history: [
                    {
                        client: "Airbus France",
                        url: "https://www.airbus.com",
                        logo: "airbus.svg",
                        actions: [
                            "Développement d'outils Intranet de capitalisation de connaissances",
                            "Développement d'une API centrale (PHP 5, composants Symfony2)",
                            "Développement d'applications single-page Javascript (ExtJS 4.x) composées de graphiques et de vues complexes",
                            "Développement d'une application d'ordonnancement (Ruby 2) afin de désynchroniser les traitements longs",
                            "Import/Export de fichiers Excel complexes (Standard OpenXML)",
                            "Participation aux phases de conception et d'arbitrage avec le client",
                            "Process pseudo-AGILE à sprint mensuel (équipe de 3 développeurs)",
                            "Interface avec le client et les équipes off-shore pour le support opérationnel"
                        ]
                    }
                ]
            }
        ],
        training: [
            { period: "2018", title: "Formation Ansible", location: "Objis Formation, Paris (75)" },
            { period: "2017", title: "Formation Git et Jenkins", location: "Objis Formation, Paris (75)" },
            { period: "2015", title: "Formation Symfony 2", location: "Orsys Formation, Toulouse (31)" },
            { period: "2008 - 2011", title: "Ingénieur en Sciences Informatiques", location: "3iL, Limoges (87)", spec: "Développement Web avancé" },
            { period: "2005 - 2008", title: "Classe préparatoire aux grandes écoles", location: "Lycée Alain-Fournier, Bourges (18)", spec: "Physique-Chimie" },
            { period: "2005", title: "Baccalauréat Scientique", location: "Lycée Saint-Cyr, Issoudun (36)" }
        ],
        languages: [
            { name: "Anglais", level: "Bilingue (Écrit/Oral/Technique)", misc: "TOEIC: 990/990 (Mai 2010)" },
            { name: "Allemand", level: "Bases scolaires" },
            { name: "Espagnol", level: "Bases scolaires" }
        ],
        social: {
            github: "https://github.com/3rgo",
            linkedin: "https://www.linkedin.com/in/romain-gonçalves-83214423",
            twitter: "https://twitter.com/3rgo_"
        }
    }
};